import { SectionLayout } from '@breezeai-frontend/cargo-ui';
import capitalize from 'lodash/capitalize';

import { useFeatureToggle } from '../../context/auth/auth-hooks';
import { useTracking } from '../../utils/snowplow/hooks';
import labels from '../labels';
import { GraphWidget } from './components/GraphWidget/GraphWidget';
import { MetricWidget } from './components/MetricWidget/MetricWidget';
import {
  type YearSelectionPayload,
  YearSelector,
} from './components/YearSelector/YearSelector';
import { useClaimsInsights, usePoliciesInsights } from './hooks';
import {
  InsightsPageContextProvider,
  useInsightsPageContext,
  useInsightsPageContextAction,
} from './InsightsPageContext';

const InsightsPageContent = () => {
  useTracking({
    eventFeature: 'insights',
    eventAction: 'page_view',
    eventTrigger: 'landing_page',
  });
  const dispatch = useInsightsPageContextAction();

  const onYearSelection = ({ startDate, endDate }: YearSelectionPayload) => {
    dispatch({ type: 'set-dates', payload: { startDate, endDate } });
  };
  const { startDate, endDate, currencyCode } = useInsightsPageContext();

  const { data: claimsInsights } = useClaimsInsights({
    params: {
      period_start: startDate,
      period_end: endDate,
      currency_code: currencyCode,
    },
  });
  const { data } = usePoliciesInsights({
    params: {
      period_start: startDate,
      period_end: endDate,
      currency_code: currencyCode,
    },
  });
  const isEnabledCertificates = useFeatureToggle('enable_certificates_page');
  const isEnabledClaims = useFeatureToggle('enable_claims');
  const isEnabledQuotes = useFeatureToggle('enable_quotes');
  const isEnabledQuotesV2 = useFeatureToggle('enable_quotes_v2');

  const policyInsights = data?.policies;
  const premiumsInsights = data?.premiums;

  const metricWidgets = [
    {
      title: capitalize(labels.terms.policy.plural),
      loading: !policyInsights,
      isEnabled: isEnabledCertificates || isEnabledQuotes || isEnabledQuotesV2,
      metricItems: policyInsights
        ? [
            {
              value: policyInsights.total,
              title: isEnabledCertificates
                ? 'Certificates'
                : `Total ${capitalize(labels.terms.policy.plural)}`,
              testId: isEnabledCertificates
                ? 'total-certificates'
                : 'total-policies',
            },
            {
              value: policyInsights.total_insured.value,
              currency: policyInsights.total_insured.currency_code,
              title: 'Total Cargo Value Insured',
              testId: 'total-cargo-value-insured',
            },
          ]
        : [],
    },
    {
      title: 'Premiums',
      loading: !premiumsInsights,
      isEnabled: !isEnabledCertificates,
      metricItems: premiumsInsights
        ? [
            {
              value: premiumsInsights.total.value,
              currency: premiumsInsights.total.currency_code,
              title: 'Total Premiums',
              testId: 'total-premiums',
            },
            {
              value: premiumsInsights.average.value,
              currency: premiumsInsights.total.currency_code,
              title: 'Average Premium Per Policy',
              testId: 'average-premium',
            },
          ]
        : [],
    },
    {
      title: 'Claims',
      loading: !claimsInsights,
      isEnabled: isEnabledClaims,
      metricItems: claimsInsights
        ? [
            {
              title: 'Claims Raised',
              testId: 'total-claims',
              value: claimsInsights.total,
            },
            {
              title: 'Total Paid Amount',
              testId: 'total-claims-paid',
              currency: claimsInsights.total_paid.currency_code,
              value: claimsInsights.total_paid.value,
            },
          ]
        : [],
    },
  ];

  return (
    <SectionLayout
      data-testid="insights-page"
      title="Insured Portfolio"
      actions={
        <YearSelector
          maxPastYear={3}
          earliestPastYear={2021}
          onChange={onYearSelection}
        />
      }
    >
      <div className="flex w-full h-full flex-col gap-y-4">
        <div className="flex flex-row w-full space-x-4 justify-between">
          {metricWidgets.map((widget) => (
            <MetricWidget {...widget} key={widget.title} />
          ))}
        </div>

        <GraphWidget />
      </div>
    </SectionLayout>
  );
};

export const InsightsPage = () => (
  <InsightsPageContextProvider>
    <InsightsPageContent />
  </InsightsPageContextProvider>
);
