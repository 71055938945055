import {
  Select,
  SelectItem,
  type SelectProps,
} from '@breezeai-frontend/cargo-ui';

import { useAuthenticatedUser } from '../../../../../context/auth/auth-hooks';
import { type Distributor } from '../../../../../model/Distributor';
import labels from '../../../../labels';

export function DistributorSelect(
  props: Omit<SelectProps<Distributor>, 'children'>,
) {
  const user = useAuthenticatedUser();

  const distributors = [
    user.distributor,
    ...(user.distributor?.children ?? []),
  ];

  return (
    <Select
      data-testid="distributor-selector"
      validationBehavior="aria"
      label={labels.fields.freightForwarder}
      placeholder={`Select ${labels.fields.freightForwarder}`}
      {...props}
    >
      {distributors.map(({ legal_name, id }) => (
        <SelectItem key={id} id={id} textValue={legal_name}>
          {legal_name}
        </SelectItem>
      ))}
    </Select>
  );
}
