import { useEffect, useState } from 'react';
import { FaShareAlt } from 'react-icons/fa';
import { Button } from '@breezeai-frontend/cargo-ui';
import capitalize from 'lodash/capitalize';

import { useFeatureToggle, useUser } from '../../../../context/auth/auth-hooks';
import { useQuote } from '../../../../network/apis/quotes/hooks';
import { useRouteParams } from '../../../../router/router-hooks';
import { reportException } from '../../../../utils/error-reporting/error-reporting';
import { getTheme } from '../../../../utils/getTheme';
import { trackPageView } from '../../../../utils/snowplow/utils';
import { QuoteCoverageDescriptions } from '../../../components/QuoteCoverageDescriptions/QuoteCoverageDescriptions';
import { QuoteDetails } from '../../../components/QuoteDetails/QuoteDetails';
import labels from '../../../labels';
import { QuoteManualReviewMessage } from '../../components/QuoteManualReviewMessage/QuoteManualReviewMessage';
import { ShareQuoteDialog } from '../../components/ShareQuoteDialog/ShareQuoteDialog';
import { WIZARD_ERROR_MESSAGES, WizardSteps } from '../../constants';
import { type PolicyWizardUrlParams } from '../../context/types';
import {
  usePolicyWizardContext,
  useSetWizardError,
  useSetWizardStep,
} from '../../hooks/context-hooks';
import { useQuoteForm } from '../../hooks/use-quote-form';
import { useShareQuote } from '../../hooks/use-share-quote';

type QuoteSummaryProps = {
  onUpdateDetails: () => void;
};

const isWtw = getTheme() === 'wtw';

export const QuoteSummary = ({ onUpdateDetails }: QuoteSummaryProps) => {
  const {
    params: { quoteId },
  } = useRouteParams<PolicyWizardUrlParams>();
  const { data: quote } = useQuote({ quoteId });

  const { step } = usePolicyWizardContext();
  const [shareModalVisible, setShareModalVisibility] = useState<boolean>(false);
  const { configuration } = useQuoteForm();
  const setWizardStep = useSetWizardStep();
  const setWizardError = useSetWizardError();
  const shareQuote = useShareQuote();
  const showShareQuote = useFeatureToggle('show_share_quote_via_email');
  const user = useUser();
  useEffect(() => {
    step === WizardSteps.QUOTE_SUMMARY &&
      trackPageView(
        {
          eventFeature: 'quote',
          eventSubfeature: 'summary',
          eventAction: 'page_view',
          quoteId: quote?.id,
        },
        user,
      );
  }, [user, step, quote]);
  const onClose = () => {
    setShareModalVisibility(false);
  };

  if (!quote || !configuration) {
    return null;
  }

  const onSend = async (recipientEmail: string) => {
    try {
      await shareQuote(quote.id, recipientEmail);
    } catch (error) {
      reportException(error);
      setWizardError({
        fallbackStep: WizardSteps.QUOTE_SUMMARY,
        message: WIZARD_ERROR_MESSAGES.shareQuote,
      });
    } finally {
      setShareModalVisibility(false);
    }
  };

  const submitButtonText = `${
    configuration.isEuFreightForwarder ? 'Refer' : 'Issue'
  } a ${capitalize(labels.terms.policy.singular)}`;

  return (
    <div className="grid grid-rows-[1fr_auto] w-full overflow-y-auto grow bg-system-grey-white mt-5 rounded-3xl p-7">
      <div
        className="flex flex-col items-center justify-center h-full w-full"
        data-testid="quote-summary"
      >
        {quote.status === 'pending_for_breeze_manual_review' ? (
          <QuoteManualReviewMessage
            exclusionReasons={quote.exclusion_reasons}
          />
        ) : (
          <>
            <QuoteDetails quote={quote} />
            <div className="mt-4 flex flex-col gap-3">
              <div className="flex gap-2.5">
                <Button
                  variant="secondary"
                  width="full"
                  data-testid="update-details-button"
                  onPress={onUpdateDetails}
                  label="Update Details"
                />
                <Button
                  variant="primary"
                  width="full"
                  data-testid="issue-policy-button"
                  onPress={() =>
                    setWizardStep(WizardSteps.SHIPMENT_INFORMATION)
                  }
                  isDisabled={step > WizardSteps.QUOTE_SUMMARY}
                  label={submitButtonText}
                />
              </div>
              {showShareQuote && (
                <Button
                  variant="ghost"
                  width="full"
                  leftIcon={<FaShareAlt />}
                  data-testid="share-quote-button"
                  onPress={() => setShareModalVisibility(true)}
                  label="Share quote via email"
                />
              )}
            </div>
          </>
        )}
      </div>
      {!isWtw && (
        <div className="border-t border-b self-end mt-5">
          <QuoteCoverageDescriptions quoteId={String(quote.id)} />
        </div>
      )}
      <ShareQuoteDialog
        visible={shareModalVisible}
        onSend={onSend}
        onClose={onClose}
      />
    </div>
  );
};
