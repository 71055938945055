import { FaBox, FaBoxOpen } from 'react-icons/fa';
import { FaCalendarAlt } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';
import { FaFileLines } from 'react-icons/fa6';
import { DateCell, Typography } from '@breeze-ai/ui-library';
import { clsxMerge } from '@breezeai-frontend/cargo-ui';
import classnames from 'classnames';

import { useFeatureToggle } from '../../../../context/auth/auth-hooks';
import { usePolicy } from '../../../../network/apis/policies/hooks';
import { getFormattedContainerMode } from '../../../../network/apis/quotes/helpers';
import { useRouteParams } from '../../../../router/router-hooks';
import { AttributesGrid } from '../../../components/AttributesGrid/AttributesGrid';
import { InfoItem } from '../../../components/InfoItem/InfoItem';
import { PageSection } from '../../../components/PageSection/PageSection';
import { CONVEYANCE_TYPE_ICONS } from '../../../constants';
import labels from '../../../labels';
import {
  getPlaceDisplayValue,
  getPlaceModelDisplayValue,
} from '../../../utils/places';
import { getPortShortDisplayValue } from '../../../utils/ports';
import { type PolicyRouteParams } from '../PolicyDetailsPage';
import styles from './PolicyShipmentDetails.module.scss';
import { getModeOfTransportDisplayString } from './utils';

type PolicyShipmentDetailsProps = {
  className?: string;
};

const gridContainerStyles =
  'grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-8';

export const PolicyShipmentDetails = ({
  className,
}: PolicyShipmentDetailsProps) => {
  const enableQuotesV2 = useFeatureToggle('enable_quotes_v2');

  const { params } = useRouteParams<PolicyRouteParams>();
  const { policyId } = params;
  const { data: policy } = usePolicy({ policyId });

  const {
    loading_place,
    origin_place,
    origin_port,
    destination_place,
    destination_port,
    delivery_place,
    etd,
    eta,
    primary_transport_mode_code,
    secondary_transport_mode_code,
    vessel_name,
    container_ids,
    container_mode,
    incoterm_code,
    external_reference,
  } = policy?.quote ?? {};

  const originPortValue = origin_port
    ? getPortShortDisplayValue(origin_port)
    : undefined;
  const originPlaceValue = origin_place
    ? getPlaceDisplayValue(origin_place)
    : undefined;

  const destinationPortValue = destination_port
    ? getPortShortDisplayValue(destination_port)
    : undefined;
  const destinationPlaceValue = destination_place
    ? getPlaceDisplayValue(destination_place)
    : undefined;

  return (
    <PageSection
      title="Shipment details"
      actions={
        external_reference && (
          <span role="booking-reference">
            <Typography variant="input">
              {labels.fields.bookingReference}:
            </Typography>{' '}
            <Typography level="h5">{external_reference}</Typography>
          </span>
        )
      }
      className={classnames(styles.wrapper, className)}
      data-testid="shipment-details"
    >
      {enableQuotesV2 && (
        <>
          <div className={clsxMerge(gridContainerStyles, 'mb-8')}>
            {loading_place && (
              <InfoItem
                title="Place of loading"
                value={getPlaceModelDisplayValue(loading_place)}
                testId="shipment-loading-place"
                Icon={FaLocationDot}
              />
            )}
            <InfoItem
              title="Origin"
              value={
                primary_transport_mode_code === 'road' ||
                primary_transport_mode_code === 'rail'
                  ? originPlaceValue
                  : originPortValue
              }
              testId="shipment-origin"
              Icon={
                primary_transport_mode_code === 'air' ||
                primary_transport_mode_code === 'sea'
                  ? CONVEYANCE_TYPE_ICONS[primary_transport_mode_code]
                  : FaLocationDot
              }
            />
            <InfoItem
              title="Destination"
              value={
                primary_transport_mode_code === 'road' ||
                primary_transport_mode_code === 'rail'
                  ? destinationPlaceValue
                  : destinationPortValue
              }
              testId="shipment-destination"
              Icon={
                primary_transport_mode_code === 'air' ||
                primary_transport_mode_code === 'sea'
                  ? CONVEYANCE_TYPE_ICONS[primary_transport_mode_code]
                  : FaLocationDot
              }
            />
            {delivery_place && (
              <InfoItem
                title="Place of Discharge"
                value={getPlaceModelDisplayValue(delivery_place)}
                testId="shipment-delivery-place"
                Icon={FaLocationDot}
              />
            )}
          </div>
          <div className={gridContainerStyles}>
            <InfoItem
              title="Vessel"
              value={vessel_name}
              Icon={
                primary_transport_mode_code
                  ? CONVEYANCE_TYPE_ICONS[primary_transport_mode_code]
                  : FaLocationDot
              }
              testId="shipment-vessel-name"
            />
            <InfoItem
              title="Mode Of Transport"
              value={
                primary_transport_mode_code
                  ? getModeOfTransportDisplayString(
                      primary_transport_mode_code,
                      Boolean(loading_place || delivery_place),
                    )
                  : undefined
              }
              Icon={
                primary_transport_mode_code
                  ? CONVEYANCE_TYPE_ICONS[primary_transport_mode_code]
                  : FaLocationDot
              }
              testId="shipment-primary-mot"
            />
            <InfoItem
              title={labels.fields.etd}
              value={
                <DateCell
                  value={etd}
                  typographyProps={{ level: 'h5', bold: true }}
                />
              }
              testId="shipment-etd"
              Icon={FaCalendarAlt}
            />
            <InfoItem
              title={labels.fields.eta}
              value={
                <DateCell
                  value={eta}
                  typographyProps={{ level: 'h5', bold: true }}
                />
              }
              testId="shipment-eta"
              Icon={FaCalendarAlt}
            />
            <InfoItem
              title={labels.fields.containers}
              value={container_ids?.join(', ')}
              Icon={FaBoxOpen}
              testId="shipment-container-ids"
            />
            <InfoItem
              title="Load Type"
              value={getFormattedContainerMode(container_mode)}
              Icon={FaBoxOpen}
              testId="shipment-container-mode"
            />
            <InfoItem
              title="Incoterm"
              value={incoterm_code}
              Icon={FaFileLines}
              testId="shipment-incoterm"
              upperCase
            />
          </div>
        </>
      )}
      {!enableQuotesV2 && (
        <AttributesGrid className={styles.shipmentDetails}>
          <InfoItem
            title="Origin"
            value={
              primary_transport_mode_code === 'road' ||
              primary_transport_mode_code === 'rail'
                ? originPlaceValue
                : originPortValue
            }
            testId="shipment-origin"
            Icon={FaLocationDot}
            className={styles.origin}
          />
          <InfoItem
            title="Destination"
            value={
              primary_transport_mode_code === 'road' ||
              primary_transport_mode_code === 'rail'
                ? destinationPlaceValue
                : destinationPortValue
            }
            testId="shipment-destination"
            Icon={FaLocationDot}
            className={styles.destination}
          />
          <InfoItem
            title="ETD"
            value={
              <DateCell
                value={etd as ISODate}
                typographyProps={{ level: 'h5', bold: true }}
              />
            }
            testId="shipment-etd"
            Icon={FaCalendarAlt}
            className={styles.etd}
          />
          <InfoItem
            title="ETA"
            value={
              <DateCell
                value={eta as ISODate}
                typographyProps={{ level: 'h5', bold: true }}
              />
            }
            testId="shipment-eta"
            Icon={FaCalendarAlt}
            className={styles.eta}
          />
          <InfoItem
            title="Primary Transport"
            value={primary_transport_mode_code}
            testId="shipment-primary-mot"
            Icon={CONVEYANCE_TYPE_ICONS[primary_transport_mode_code!]}
            capitalize={true}
          />
          <InfoItem
            title="Secondary Transport"
            value={secondary_transport_mode_code}
            testId="shipment-secondary-mot"
            Icon={
              CONVEYANCE_TYPE_ICONS[
                secondary_transport_mode_code ?? primary_transport_mode_code!
              ]
            }
            capitalize={true}
          />

          <InfoItem
            title="Vessel"
            value={vessel_name}
            Icon={CONVEYANCE_TYPE_ICONS[primary_transport_mode_code!]}
            testId="shipment-vessel-name"
          />
          <InfoItem
            title="Containers"
            value={container_ids?.join(', ')}
            Icon={FaBox}
            testId="shipment-container-ids"
          />
          <InfoItem
            title="Load Type"
            value={getFormattedContainerMode(container_mode)}
            Icon={FaBox}
            testId="shipment-container-mode"
          />
          <InfoItem
            title="Incoterm"
            value={incoterm_code}
            Icon={FaFileLines}
            testId="shipment-incoterm"
            upperCase
          />
        </AttributesGrid>
      )}
    </PageSection>
  );
};
